<template>

  <svg version="1.1" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px" width="570px" height="150px" viewBox="0 0 570 150" xml:space="preserve">
<circle fill="none" cx="71.5" cy="77.5" r="51.5" stroke="#88CE02" stroke-width="4"/>
    <ellipse fill="none" stroke="#88CE02" stroke-width="4" stroke-miterlimit="10" cx="241.4" cy="77.5" rx="78.9" ry="51.5"/>
    <rect x="354" y="26" fill="none" stroke="#88CE02" stroke-linecap="square" stroke-width="4" stroke-miterlimit="30" width="103" height="103" id="rect" />
    <polyline fill="none" stroke="#88CE02" stroke-width="4" stroke-miterlimit="10" points="536.1,129 487.3,74.2 536.1,26 "/>
</svg>
</template>

<script>

import {gsap} from "gsap";
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(DrawSVGPlugin);

export default {
  name: "DrawSVGPluginNr1",


  mounted() {

    var shapes = "rect, circle, ellipse, polyline",
        tl = gsap.timeline({repeat:1, yoyo:true});

    tl.fromTo(shapes, {drawSVG:"100%"}, {duration: 1, drawSVG:"50% 50%", stagger: 0.1})
        .fromTo(shapes, {drawSVG:"0%"}, {duration: 0.1, drawSVG:"10%", immediateRender:false}, "+=0.1")
        .to(shapes, {duration: 1, drawSVG:"90% 100%", stagger: 0.5})
        .to(shapes, {duration: 1, rotation:360, scale:0.5, drawSVG:"100%", stroke:"white", strokeWidth:6, transformOrigin:"50% 50%"})
        .to(shapes, {duration: 0.5, stroke:"red", scale:1.5, opacity:0, stagger: 0.2})



}}
</script>

<style scoped lang="scss">

#slider {
  width:400px;
  margin:30px auto;
  background-color: #222;
  display: inline-block;
}

#slider, #slider a {
  background-image: none;
  border: 1px solid #555;
}
#slider a {
  background-color: #444;
}
</style>