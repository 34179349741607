<template>
<button class="bg-gray-500 w-1/12 mx-auto mb-5" @click="animation">Animation On</button>

  <svg id="Layer_1" class="w-11/12 mx-auto" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 618.44 103.87">
    <g id="Celebrating">
      <path id="first-shape"
            d="M696.7,195.94A38.27,38.27,0,0,0,711.25,193v11.73a45.51,45.51,0,0,1-16.9,3.29c-17.84,0-27.7-10.68-27.7-30.16s9.86-30.28,27.7-30.28a45.51,45.51,0,0,1,16.9,3.28v11.74a38.26,38.26,0,0,0-14.55-2.82c-9.86,0-16,2.93-16,18.08S686.84,195.94,696.7,195.94Z"
            transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path
          d="M774.51,191.36v11.39A45.58,45.58,0,0,1,752.45,208c-11.62,0-27.23-4.7-27.23-29.58,0-17.48,7-31,26.18-31,18.3,0,26.17,13.73,26.17,28.4a71.48,71.48,0,0,1-.24,7.28H739.66c.82,11.26,8.09,12.79,14.9,12.79A45.81,45.81,0,0,0,774.51,191.36Zm-34.85-18h23.59C762.54,166,758.91,159,751.4,159,744.47,159,740.48,163,739.66,173.41Z"
          transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path d="M794.12,207.09V126.22H808.2v80.87Z" transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path
          d="M877.57,191.36v11.39A45.65,45.65,0,0,1,855.5,208c-11.62,0-27.23-4.7-27.23-29.58,0-17.48,7-31,26.18-31,18.31,0,26.17,13.73,26.17,28.4a71.48,71.48,0,0,1-.24,7.28H842.71c.82,11.26,8.1,12.79,14.91,12.79A45.88,45.88,0,0,0,877.57,191.36Zm-34.86-18H866.3C865.6,166,862,159,854.45,159,847.52,159,843.53,163,842.71,173.41Z"
          transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path
          d="M949.17,177.16c0,27.82-18.43,30.87-26.3,30.87-18.19,0-24.64-10.21-24.64-19.72v-62.2h14.08v22.41a53.07,53.07,0,0,1,9.62-.93C939.42,147.59,949.17,158,949.17,177.16Zm-14.21-.7c0-13.85-5.4-17.73-13.5-17.73a40.29,40.29,0,0,0-9.15.94V189.6c0,4.93,4.34,6.34,10.56,6.34C931.91,195.94,935,189.84,935,176.46Z"
          transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path d="M996.47,147.59v12.2a58.3,58.3,0,0,0-15.15,2v45.3H967.24V153.81A79.09,79.09,0,0,1,996.47,147.59Z"
            transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path
          d="M1058.91,172.47v15.84c0,9.51-6.45,19.72-24.64,19.72-18.55,0-25.12-10.33-25.12-20.07,0-4.34,1.76-18.43,25.23-18.43a70.8,70.8,0,0,1,10.33.82c0-6.34-3.28-11-12.44-11a46.21,46.21,0,0,0-17.14,2.93V150.87a47.56,47.56,0,0,1,18-3.28C1049.76,147.59,1058.91,156.38,1058.91,172.47Zm-14.08,6.81a57.19,57.19,0,0,0-8.45-.71c-9.51,0-13.26,2.82-13.26,7.63,0,6.1,3.63,9.39,11,9.39,7.86,0,10.68-3.52,10.68-8.92Z"
          transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path
          d="M1095.18,160.61v28.87c0,5.17,2,6.46,7.16,6.46a16.21,16.21,0,0,0,6.69-1.29v11.62a23.81,23.81,0,0,1-9.27,1.76c-11.62,0-18.66-7.28-18.66-19.37v-28h-9.86V148.52h9.86V131.15h14.08v17.37H1109v12.09Z"
          transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path d="M1127,140.54V127.63h14.43v12.91Zm.23,66.55V148.52h14.08v58.57Z" transform="translate(-666.65 -126.11)"
            fill="#fff"/>
      <path
          d="M1216.55,171.88v35.21h-14V170.71c0-5.17-1.06-10.68-13.85-10.68a61,61,0,0,0-10.91,1.05v46h-14.09V154a61.58,61.58,0,0,1,26.88-6.45C1206.92,147.59,1216.55,156.62,1216.55,171.88Z"
          transform="translate(-666.65 -126.11)" fill="#fff"/>
      <path
          d="M1285.09,166.83V208.5c0,8-3.4,21.48-26.4,21.48a57.87,57.87,0,0,1-16.91-2.35V215.07a48.42,48.42,0,0,0,15.26,2.35c14,0,14-5.16,14-8.92v-3.17a44.86,44.86,0,0,1-8.92.94c-17,0-26.41-10.21-26.41-28.64,0-27.11,17.84-30,25.47-30C1278.87,147.59,1285.09,157.56,1285.09,166.83Zm-14.08-.11c0-5.29-4.58-6.58-9.86-6.58-7,0-11.39,4.46-11.39,17.73,0,14.08,4.94,16.9,12.8,16.9a42.78,42.78,0,0,0,8.45-.83Z"
          transform="translate(-666.65 -126.11)" fill="#fff"/>
    </g>
  </svg>

</template>

<script>

import {gsap} from "gsap";
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(DrawSVGPlugin);

export default {
  name: "DrawSVGPluginNr2",


  methods: {


    animation() {

      var celebratingTL = gsap.timeline({repeat: -1, repeatDelay: 2})

      celebratingTL.fromTo("#Celebrating path", {drawSVG: "0%"}, {duration: 4, drawSVG: "100%", ease: "expo.inOut"})

      celebratingTL.to("#Celebrating path", {duration: 1, fill: "#fff"})


    }


  },



  mounted() {




}}
</script>

<style scoped lang="scss">
path {
  stroke-width: 3px;
  stroke: black;
  fill: none;
}

</style>