<template>



  <div class="flex flex-col justify-center">

    <h1 class="text-center">NR.1</h1>
  <DrawSVGPluginNr1 class="self-center" />

    <h1 class="text-center mb-4">NR.2</h1>
    <DrawSVGPluginNr2 />


    <h1 class="text-center mb-4">NR.3</h1>
    <DrawSVGPluginNr3 />

  </div>
</template>

<script>

import DrawSVGPluginNr1 from '@/components/animations/DrawSVGPlugin/DrawSVGPluginNr1.vue'
import DrawSVGPluginNr2 from '@/components/animations/DrawSVGPlugin/DrawSVGPluginNr2.vue'
import DrawSVGPluginNr3 from '@/components/animations/DrawSVGPlugin/DrawSVGPluginNr3.vue'

export default {
  name: "DrawSVGPlugin",


  components: {
    DrawSVGPluginNr1,  DrawSVGPluginNr2, DrawSVGPluginNr3,
  },

}
</script>

<style scoped>


</style>