<template>
<button class="bg-gray-500 w-1/12 mx-auto mb-5" @click="animation">Animation On</button>

  <svg xmlns="http://www.w3.org/2000/svg" width="350" height="600" viewBox="0 0 700 1200">
    <defs>
      <mask id="theMask" maskUnits="userSpaceOnUse">
        <path d="M368.85,565.37c24.3,26.42,61.8,70.46,79.51,101.69s35,66.55,29.45,102A122.25,122.25,0,0,1,430,847.84c-25,18.39-57.55,21.15-88.31,16.77-14.16-2-27.18-6.49-37.54-16.35-8.23-7.83-18-9.72-24-15.72-10.08-10-2.31-27.27-35.65-26.13-12.5.42-23.27-1.61-37-.83l-10.4,104.48c-.79,7.93-6.16,38-6.16,38h56.85c4-6.33,5.74-22.79,9.71-29.12,55.93,34.67,120.46,37.55,181.26,19.33,56.42-16.92,115.13-61.61,141-114.55s20.88-115.69-.5-170.57c-10.52-27-18.81-52.57-35.8-76-25.74-35.55-74.65-71.86-105.68-102.9" fill="none" stroke="#ffffff" stroke-miterlimit="10" stroke-width="37"/>
        <path d="M369.77,566.29c-24.29-26.43-55.92-62.41-73.62-93.64s-28.36-67.72-22.83-103.19a122.26,122.26,0,0,1,47.82-78.76c25.38-18.66,58.5-21.23,89.67-16.57,17.5,2.62,24.62,7.26,37.78,19.1,10.4,9.37,22.5,10.39,24.9,24.08,1.09,6.2,11.94,18.95,18.23,18.59l52-2.93,10.39-104.49c.79-7.93,2-34,2-34l-54.31-.66c-4,6.32-6.48,21.31-10.46,27.64-57.28-28.64-118.12-39.4-178.92-21.17-56.42,16.91-115.13,61.61-141,114.54S143,431.48,164.37,486.36c10.51,27,26.39,51.56,43.38,75,25.75,35.55,74.66,71.86,105.69,102.9" fill="none" stroke="#ffffff" stroke-miterlimit="10" stroke-width="37"/>
      </mask>
    </defs>
    <g mask="url(#theMask)">

      <path id="mask" d="M403.05,583.1a660.25,660.25,0,0,1,74.79,111.09,129.5,129.5,0,0,1-223.5,130.57H218.08L207.39,933.9h28l7.86-16.11,6.45-13.45.09,0,0-.09,13.36,6.51a242.67,242.67,0,0,0,104.24,23.7c66.8,0,125.22-23.35,164.5-65.74,36.07-38.95,53.56-91.46,49.24-147.87a220.46,220.46,0,0,0-17.2-69.67h0c-24.08-60.85-61.4-111.81-108.11-153.37l-18.3-15.28s105.07,56.06,155.52,160.92a249.69,249.69,0,0,1,17.85,75.13c5,64.8-15.33,125.33-57.12,170.44-45,48.56-111.19,75.31-186.4,75.31a272.68,272.68,0,0,1-103.62-20.61l-9.76,20H174.46L191,794.9h80.91l4.21,7.92A98.72,98.72,0,0,0,395.4,850.11a100,100,0,0,0,56.1-141.85A629.7,629.7,0,0,0,380.1,602.2l-35.56-42.71A660.25,660.25,0,0,1,269.75,448.4a129.5,129.5,0,0,1,223.5-130.58h36.26l10.7-109.13h-28l-7.86,16.11-6.45,13.44-.09,0,0,.09-13.36-6.52A242.59,242.59,0,0,0,380.2,208.08c-66.8,0-125.22,23.35-164.49,65.74-36.08,38.94-53.57,91.46-49.25,147.87a220.4,220.4,0,0,0,17.2,69.66h0c24.09,60.86,61.4,111.82,108.11,153.37L310.05,660S205,604,154.53,499.1A249.83,249.83,0,0,1,136.68,424c-5-64.8,15.33-125.33,57.12-170.44,45-48.57,111.19-75.31,186.4-75.31a272.73,272.73,0,0,1,103.63,20.61l9.75-20h79.56L556.59,347.68H475.67l-4.21-7.91a98.72,98.72,0,0,0-119.26-47.3,100,100,0,0,0-56.11,141.86,630.22,630.22,0,0,0,71.4,106.05Z"/>
    </g>

  </svg>

</template>

<script>

import {gsap} from "gsap";
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(DrawSVGPlugin);

export default {
  name: "DrawSVGPluginNr3",


  methods: {


    animation() {

      gsap.config({trialWarn: false});
      gsap.from("#theMask path", {
        duration: 3,
        ease:"power2.inOut",
        drawSVG: 0,
        repeat: -1,
        yoyo: true,
        repeatDelay: 0.5
      });



    }


  },



  mounted() {




}}
</script>

<style scoped lang="scss">
svg {
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 90vh;
}

</style>